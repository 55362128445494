@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins', sans-serif;
}

.gradient-bg {
  background: linear-gradient(90deg, #4a90e2 0%, #5c6bc0 100%);
}

.card-hover {
  transition: all 0.3s ease-in-out;
}

.card-hover:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.btn-primary {
  @apply bg-yellow-400 text-blue-900 font-bold py-3 px-6 rounded-full text-lg hover:bg-yellow-300 transition duration-300 inline-block;
}

.section-title {
  @apply text-4xl font-bold text-center mb-12 relative;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background-color: #4a90e2;
}

/* Add padding-top class to account for header */
.pt-20 {
  padding-top: 5rem; /* Adjust this value as per the header height */
}
